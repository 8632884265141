import Vue from "vue";
import VueRouter from "vue-router";
// import HomeView from "../views/HomeView.vue";
import { getTokenExpireDate } from "@/helpers/TokenHelper";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/",
  //   name: "home",
  //   component: HomeView,
  //   meta: {
  //     mainTitle: "Main Page",
  //     requiresAuth: true,
  //     breadcrumb: [
  //       {
  //         text: "Home Page",
  //         disabled: true,
  //         href: "",
  //       },
  //     ],
  //   },
  // },
  {
    path: "/",
    redirect: () => {
      // the function receives the target route as the argument
      // we return a redirect path/location here.
      return { name: "MediaPlans" };
    },
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login/Index"),
    meta: {
      mainTitle: "User Login",
      requiresAuth: false,
      breadcrumb: [
        // {
        //   text: "Home Page",
        //   disabled: true,
        //   href: "",
        // },
        {
          text: "Login",
          disabled: true,
          href: "",
        },
      ],
    },
  },
  {
    path: "/companies",
    name: "Companies",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Companies/Index"),
    meta: {
      mainTitle: "Company Management",
      requiresAuth: true,
      breadcrumb: [
        // {
        //   text: "Home Page",
        //   disabled: false,
        //   href: "/",
        // },
        {
          text: "Companies",
          disabled: true,
          href: "",
        },
      ],
    },
  },
  {
    path: "/companies/edit/:id?",
    name: "CompanyEdit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Companies/Edit"),
    meta: {
      mainTitle: "Company Management Add/Edit",
      requiresAuth: true,
      breadcrumb: [
        // {
        //   text: "Home Page",
        //   disabled: false,
        //   href: "/",
        // },
        {
          text: "Companies",
          disabled: false,
          href: "/companies",
        },
        {
          text: "Company Add/Edit",
          disabled: true,
          href: "",
        },
      ],
    },
  },
  {
    path: "/users",
    name: "Users",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Users/Index"),
    meta: {
      mainTitle: "User Management",
      requiresAuth: true,
      breadcrumb: [
        // {
        //   text: "Home Page",
        //   disabled: false,
        //   href: "/",
        // },
        {
          text: "Users",
          disabled: true,
          href: "",
        },
      ],
    },
  },
  {
    path: "/users/edit/:id?",
    name: "UserEdit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Users/Edit"),
    meta: {
      mainTitle: "User Management Add/Edit",
      requiresAuth: true,
      breadcrumb: [
        // {
        //   text: "Home Page",
        //   disabled: false,
        //   href: "/",
        // },
        {
          text: "Users",
          disabled: false,
          href: "/users",
        },
        {
          text: "User Add/Edit",
          disabled: true,
          href: "",
        },
      ],
    },
  },
  {
    path: "/spec-templates",
    name: "SpecTemplates",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SpecManagements/Index"),
    meta: {
      mainTitle: "Spec Management",
      requiresAuth: true,
      breadcrumb: [
        // {
        //   text: "Home Page",
        //   disabled: false,
        //   href: "/",
        // },
        {
          text: "Specs",
          disabled: true,
          href: "",
        },
      ],
    },
  },
  {
    path: "/spec-templates/edit/:id?",
    name: "SpecTemplatesEdit",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SpecManagements/Edit"),
    meta: {
      mainTitle: "Spec Management Add/Edit",
      requiresAuth: true,
      breadcrumb: [
        // {
        //   text: "Home Page",
        //   disabled: false,
        //   href: "/",
        // },
        {
          text: "Specs",
          disabled: false,
          href: "/spec-templates",
        },
        {
          text: "Spec Add/Edit",
          disabled: true,
          href: "",
        },
      ],
    },
  },
  {
    path: "/media-plans",
    name: "MediaPlans",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MediaPlans/Index"),
    meta: {
      mainTitle: "Media Spec List",
      requiresAuth: true,
      breadcrumb: [
        // {
        //   text: "Home Page",
        //   disabled: false,
        //   href: "/",
        // },
        {
          text: "Media Spec List",
          disabled: true,
          href: "",
        },
      ],
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("app_token") !== null) {
      if (getTokenExpireDate() < Date.now() / 1000) {
        localStorage.clear();
        next("/Login");
      } else {
        next();
      }
    } else {
      next("/Login");
    }
  } else {
    next();
  }
});

export default router;
